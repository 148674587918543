@import '@styles/theme/theme';

.button {
  position: relative;
  display: flex;
  width: 40px !important;
  height: 40px !important;
  padding: 2px !important;
  margin-left: 10px;
  color: white;
  cursor: pointer;
  background-color: $secondary;
  border: solid 2px $secondary;
  border-radius: 10px !important;
  transition: all 100ms linear;

  span {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    display: block;
    margin: auto;
  }

  &:hover {
    background-color: $primary;
  }
}

.button-large {
  @include reset-button();

  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 54px;
  padding: 15px 26px;
  font-size: 18px;
  color: $primary;
  cursor: pointer;
  background-color: transparent !important;
  border: 2px solid $primary;
  border-radius: 30px;
  transition: 0.3s;

  .img-container {
    box-sizing: border-box;
    width: 18px !important;
    height: 18px !important;
  }

  &:hover {
    color: white;
    background-color: $primary !important;

    img {
      filter: brightness(0) invert(1);
    }
  }

  .button-text {
    margin-left: 20px;
  }
}
