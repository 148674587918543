@import '@styles/theme/theme';

.download {
  display: none;

  button img {
    // !important in order to override global navbar filter
    filter: brightness(0) invert(1) !important;
  }

  @include media('ms') {
    display: flex;
  }
}
