@import '@styles/theme/theme';

.container {
  min-height: 100vh;
  background-color: $primary;

  @include container-padding();

  @include bp-medium() {
    // this is bad, but it's due to the layout down-shift for the navbar
    transform: translateY(40px);
  }
}

.content {
  max-width: 988px;
  padding-top: $navbar-height;
  margin: 0 auto;

  p {
    font-family: $title-font;
    font-size: 48px;
    font-weight: 600;
    line-height: 80px;
    color: $secondary;
    text-align: center;

    @include bp-medium() {
      font-size: 24px;
      line-height: 40px;
    }

    &:last-of-type {
      margin-top: 100px;

      @media screen and (width <= 1200px) {
        padding-bottom: 150px;
      }

      @include bp-medium() {
        margin-top: 50px;
      }
    }
  }
}

.social-container {
  position: absolute;
  right: 20px;
  bottom: 0;
  font-size: 19px;
  color: #fff;
  text-align: center;

  @include bp-medium() {
    position: static;
    margin: 20px auto 0;
  }
}

.placeholder {
  display: none;
  height: 150px;

  @media screen and (width <= 1200px) {
    display: block;
  }
}

.image {
  position: absolute;
  top: 150px;
  left: 50px;
  width: 150px;

  @include bp-large() {
    display: none;
  }
}

.yellow {
  color: $tertiary;
}
